<template>
  <div class="container-fluid p-0">
    <div class="row mb-1 justify-content-center ">
      <div class="col-12 col-sm-6 col-xl-5 text-center mb-2">
        <div class="mb-1">
          <b-img
            rounded="circle"
            alt="Circle image"
            :src="pic"
            style="max-width:209px;width:100%"
            @error="setAltImg"
          />
        </div>
        <div class="text-left">
          <b-form-file
            v-model="file"
            type="file"
            placeholder="เพิ่มรูปลูกค้า"
          />
        </div>
      </div>
      <div class="col-12 col-sm-6 col-xl-7 ">
        <validation-observer ref="customerRules">
          <table>
            <thead>
              <tr>
                <th style="min-width:90px; width:25%;" />
                <th style="width:70%;" />
              </tr>
            </thead>
            <tbody>
              <tr v-if="codeId">
                <td class="pr-1">
                  <p>ชื่อผู้ใช้งาน</p>
                </td>
                <td>
                  <p>{{ codeId }}</p>
                </td>
              </tr>
              <tr>
                <td class="pr-1">
                  <p>ชื่อจริง</p>
                </td>
                <td>
                  <validation-provider
                    #default="{ errors }"
                    name="First Name"
                    rules="required"
                  >
                    <b-form-input
                      id="h-first-name"
                      v-model="input.firstName"
                      :state="errors.length > 0 ? false:null"
                      autofocus
                    />
                    <small
                      v-if="errors.length > 0"
                      class="text-danger"
                    >กรุณากรอกข้อมูลชื่อจริง</small>
                  </validation-provider>
                </td>
              </tr>
              <tr>
                <td class="pr-1">
                  <p>นามสกุล</p>
                </td>
                <td>
                  <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    rules="required"
                  >
                    <b-form-input
                      id="h-last-name"
                      v-model="input.lastName"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small
                      v-if="errors.length > 0"
                      class="text-danger"
                    >กรุณากรอกข้อมูลนามสกุล</small>
                  </validation-provider>
                </td>
              </tr>
              <tr>
                <td class="pr-1">
                  <p>อีเมล</p>
                </td>
                <td>
                  <b-form-input
                    id="h-last-name"
                    v-model="input.email"
                  />
                </td>
              </tr>
              <tr>
                <td class="pr-1">
                  <p>เบอร์โทร</p>
                </td>
                <td>
                  <validation-provider
                    #default="{ errors }"
                    name="Type"
                    rules="required"
                  >
                    <b-form-input
                      id="h-telephone-name"
                      v-model="input.telephoneNumber"
                      type="number"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      :state="errors.length > 0 ? false:null"
                      format="decimal"
                    />
                    <small
                      v-if="errors.length > 0"
                      class="text-danger"
                    >กรุณากรอกเบอร์โทรศัพท์</small>
                  </validation-provider>
                </td>
              </tr>
              <tr style="height: 60px;">
                <td
                  colspan="2"
                  class="text-center"
                >
                  <b-button-group>
                    <b-button
                      :variant="input.type==='U'?'primary':'outline-primary'"
                      @click="onChangeStatusCustomer('U')"
                    >
                      ทั่วไป
                    </b-button>
                    <b-button
                      :variant="input.type==='V'?'primary':'outline-primary'"
                      @click="onChangeStatusCustomer('V')"
                    >
                      ตัวแทน
                    </b-button>
                  </b-button-group>
                </td>
              </tr>
              <tr v-if="input.type === 'V'">
                <td class="pr-1">
                  <p>
                    ประเทศ
                  </p>
                </td>
                <td>
                  <validation-provider
                    #default="{ errors }"
                    name="Type"
                    rules="required"
                  >
                    <b-input-group>
                      <b-form-select
                        v-model="input.countryId"
                        :options="DataCountry"
                        :state="errors.length > 0 || input.countryId === null && checkIsSelect !== true ? false:null"
                      />
                      <b-input-group-append>
                        <b-button
                          variant="outline-primary"
                          style="padding: 0px;
    width: 40px;"
                          @click="onClickopenModalcountry"
                        >
                          <b-icon-plus
                            font-scale="1.6"
                          />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>

                    <small
                      v-if="errors.length > 0 || checkIsSelect === false"
                      class="text-danger"
                    >กรุณาเลือกประเทศ</small>
                  </validation-provider>
                </td>
              </tr>
            </tbody>
          </table>
        </validation-observer></div>
    </div>
    <div class="row mb-2">
      <div class="col-12">
        <div class="mb-1">
          <b-button
            variant="primary"
            class="mr-1"
            @click="openModalAdd()"
          >
            เพิ่มที่อยู่
          </b-button>
        </div>
        <b-table
          v-if="reloadtable"
          :sticky-header="true"
          :no-border-collapse="false"
          responsive
          :items="input.address"
          :fields="fieldsAddress"
          class="mb-0"
        >
          <template #cell(nameAddress)="data">
            <p>{{ data.item.name }}<br>{{ `${data.item.address}  ${data.item.district}  ${data.item.city}  ${data.item.province} ${data.item.postal}` }}<br>{{ data.item.telephone }}</p>
          </template>
          <template #cell(default)="data">
            <b-form-checkbox
              v-model="data.item.status"
              @change="onChangeDefaultAddress(data.index)"
            />
          </template>
          <template #cell(button)="data">
            <div
              class="d-flex"
            >
              <button
                class="btn btn-sm btn-warning mr-1"
                type="button"
                @click="onEditAddress(data.index)"
              >
                แก้ไข
              </button>
              <button
                class="btn btn-sm btn-danger"
                type="button"
                @click="onDeleteAddress(data.index)"
              >
                ลบ
              </button>
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <div class="mb-1 d-flex justify-content-center mt-1">
      <b-button
        v-if="codeforEdit === ''"
        variant="primary"
        class="mr-1"
        @click="addCustomer"
      >
        ยืนยัน
      </b-button>
      <b-button
        v-if="codeforEdit !== ''"
        variant="warning"
        class="mr-1"
        @click="updateCustomer"
      >
        แก้ไข
      </b-button>
      <b-button
        variant="danger"
        @click="onBackButton"
      >
        ย้อนกลับ
      </b-button>
    </div>
    <b-modal
      v-model="openModalAddcountry"
      hide-footer
      header-bg-variant="primary"
      size="sm"
      title="เพิ่มประเทศตัวแทน"
      centered
      no-close-on-backdrop
    >
      <div class="row mt-1">
        <div class="col-12">
          <validation-observer ref="addCountryRules">
            <validation-provider
              #default="{ errors }"
              name="Type"
              rules="required"
            >
              <b-form-group
                label="ประเทศ"
                label-for="h-first-name"
                label-cols-md="4"
                class="fromlabel mb-1"
              >
                <v-select
                  v-model="inputscountry"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="title"
                  :options="datascountry"
                  :reduce="option => option.value"
                />
                <small
                  v-if="errors.length > 0 && inputscountry ===''"
                  class="text-danger"
                >กรุณากรอกประเทศ</small>
              </b-form-group>
            </validation-provider>
          </validation-observer>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-2 mb-2">
        <b-button
          class="rounded text-center"
          style="height:40px;padding: 0px;    width: 90px !important;"
          variant="primary"
          @click="onClickAddcountry "
        >
          <span>ตกลง</span>
        </b-button>
        <b-button
          class="rounded text-center ml-1"
          style="height:40px;padding: 0px;    width: 90px !important;"
          variant="secondary"
          @click="() => { openModalAddcountry= false }"
        >

          <span>ยกเลิก</span>
        </b-button>
      </div>
    </b-modal>
    <b-modal
      v-model="IsopenmodalAddress"
      hide-footer
      header-bg-variant="primary"
      size="lg"
      title="ที่อยู่สำหรับจัดส่ง"
    >
      <div class="row">
        <div class="col-12 col-sm-11 col-lg-9">
          <b-form-group
            label="ผู้รับสินค้า"
            label-for="h-first-name"
            label-cols-md="4"
            :style="sizepagee!=='xs'?'text-align: end':''"
            class="fromlabel mb-1"
          >
            <b-form-input
              v-model="inputsAdess.name"
              placeholder="ชื่อ-นามสกุล"
            />
          </b-form-group>
          <b-form-group
            label="มือถือ"
            type="tel"
            label-for="h-first-name"
            label-cols-md="4"
            :style="sizepagee!=='xs'?'text-align: end':''"
            class="fromlabel mb-1"
          >
            <b-form-input
              v-model="inputsAdess.phone"
              type="tel"
              placeholder="ระบุหมายเลขโทรศัพท์มือถือ 10 หลัก"
            />
          </b-form-group>
          <b-form-group
            label="จังหวัด"
            label-for="h-first-name"
            label-cols-md="4"
            :style="sizepagee!=='xs'?'text-align: end':''"
            class="fromlabel mb-1"
          >
            <b-form-select
              v-model="inputsAdess.provinces"
              :options="provinces"
            />
          </b-form-group>
          <b-form-group
            label="อำเภอ"
            label-for="h-first-name"
            label-cols-md="4"
            :style="sizepagee!=='xs'?'text-align: end':''"
            class="fromlabel mb-1"
          >
            <b-form-select
              v-model="inputsAdess.amphure"
              :options="amphure"
            />
          </b-form-group>
          <b-form-group
            label="ตำบล"
            label-for="h-first-name"
            label-cols-md="4"
            :style="sizepagee!=='xs'?'text-align: end':''"
            class="fromlabel mb-1"
          >
            <b-form-select
              v-model="inputsAdess.tombons"
              :options="tombons"
            />
          </b-form-group>
          <b-form-group
            label="รหัสไปษณีย์"
            label-for="h-first-name"
            label-cols-md="4"
            :style="sizepagee!=='xs'?'text-align: end':''"
            class="fromlabel mb-1"
          >
            <b-form-input
              v-model="inputsAdess.zipcode"
              readonly
            />
          </b-form-group>
          <b-form-group
            label="ที่อยู่"
            label-for="h-first-name"
            label-cols-md="4"
            :style="sizepagee!=='xs'?'text-align: end':''"
            class="fromlabel mb-1"
          >
            <b-form-textarea
              v-model="inputsAdess.address"
              placeholder="บ้านเลขที่/หมู่ที่/ซอย/"
              rows="2"
            />

          </b-form-group>
          <b-form-group
            label=""
            label-for="h-first-name"
            label-cols-md="4"
            class="fromlabel mb-1"
          >
            <b-form-checkbox
              v-model="inputsAdess.addressfirst"
            >
              <span>ตั้งเป็นที่อยู่เริ่มต้น</span>
            </b-form-checkbox>

          </b-form-group>

        </div>
      </div>
      <div class="d-flex justify-content-center mt-2 mb-2">
        <b-button
          class="rounded text-center"
          style="height:40px;padding: 0px;    width: 90px !important;"
          variant="primary"
          @click="onClickAddAddress"
        >
          <span>ตกลง</span>
        </b-button>
        <b-button
          class="rounded text-center ml-1"
          style="height:40px;padding: 0px;    width: 90px !important;"
          variant="secondary"
        >
          <span>ยกเลิก</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { BFormCheckbox, BTable, BImg, BButton, BFormInput, BFormSpinbutton, BFormSelect, BFormFile, BFormTextarea, BFormDatepicker, BFormGroup, BButtonGroup, BInputGroup, BInputGroupAppend, BIconPlus } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
// import { Handler } from 'leaflet'
import vSelect from 'vue-select'
import DatePicker from '@/views/components/production/datePicker.vue'
import PlaceHolder from '@/assets/images/production/user.png'
import data_country from '@/data-json/country.json'
import data_amphure from '@/data-json/thai_amphures.json'
import data_provinces from '@/data-json/thai_provinces.json'
import data_tombons from '@/data-json/thai_tombons.json'

export default defineComponent({
  name: 'addCustomer',
  props: ['code', 'enter', 'status'],
  components: {
    BImg,
    BFormCheckbox,
    BTable,
    BButton,
    BFormInput,
    BFormSpinbutton,
    BFormSelect,
    BFormFile,
    BFormTextarea,
    BFormDatepicker,
    DatePicker,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BButtonGroup,
    BInputGroup,
    BInputGroupAppend,
    BIconPlus,
    vSelect

  },
  data () {
    return {
      openModalAddcountry: false,
      datascountry: [],
      file: null,
      required,
      isSelected: null,
      checkIsSelect: true,
      codeId: null,
      input: {
        id: '',
        firstName: '',
        lastName: '',
        type: 'U',
        telephoneNumber: '',
        employerId: JSON.parse(localStorage.userData).id,
        countryId: null,
        email: '',
        address: []
      },
      inputscountry: '',
      pic: '',
      DataCountry: [],
      itemsAddress: [],
      fieldsAddress: [
        { key: 'index', label: 'index', thClass: 'd-none', tdClass: 'd-none' },
        {
          key: 'nameAddress', label: 'ที่อยู่', thStyle: 'min-width: 360px'
        },
        { key: 'default', thStyle: 'min-width: 100px', label: 'เริ่มต้น' },
        { key: 'button', label: '', thStyle: 'min-width: 150px', tdClass: 'text-center', thClass: 'text-center' }
      ],
      inputsAdess: {
        id: '',
        name: '',
        provinces: '',
        amphure: '',
        tombons: '',
        zipcode: '',
        adressfirst: false,
        phone: '',
        address: ''
      },
      IsopenmodalAddress: false,
      amphure: [],
      provinces: [],
      tombons: [],
      reloadtable: true
    }
  },
  computed: {
    codeforEdit () {
      const check = this.code === undefined ? '' : this.code
      return check !== '' ? this.code : ''
    },
    sizepagee () {
      return this.$store.getters['app/currentBreakPoint']
    }
  },
  created () {
    this.loader()
  },
  methods: {
    onEditAddress (index) {
      this.IsopenmodalAddress = true
      this.inputsAdess.provinces = this.input.address[index].province
      this.inputsAdess.amphure = this.input.address[index].city
      this.inputsAdess.tombons = this.input.address[index].district
      this.inputsAdess.zipcode = this.input.address[index].postal
      this.inputsAdess.name = this.input.address[index].name
      this.inputsAdess.address = this.input.address[index].address
      this.inputsAdess.addressfirst = this.input.address[index].status
      this.inputsAdess.phone = this.input.address[index].telephone
      this.inputsAdess.id = this.input.address[index].id
      this.loaderAdress()
    },
    onDeleteAddress (index) {
      this.input.address = this.input.address.filter((x, i) => i !== index)
    },
    onChangeDefaultAddress (index) {
      this.input.address.forEach((data, i) => {
        if (index === i) this.input.address[i].status = true
        else { this.input.address[i].status = false }
      })
    },
    onClickAddAddress () {
      if (this.inputsAdess.id !== '') {
        this.input.address.forEach((data, index) => {
          if (data.id === this.inputsAdess.id) {
            this.input.address[index].name = this.inputsAdess.name
            this.input.address[index].telephone = this.inputsAdess.phone
            this.input.address[index].id = this.inputsAdess.id
            this.input.address[index].province = this.inputsAdess.provinces
            this.input.address[index].city = this.inputsAdess.amphure
            this.input.address[index].district = this.inputsAdess.tombons
            this.input.address[index].address = this.inputsAdess.address
            this.input.address[index].postal = this.inputsAdess.zipcode
            this.input.address[index].status = this.inputsAdess.addressfirst
            if (this.inputsAdess.addressfirst) this.onChangeDefaultAddress(index)
          }
        })
      } else {
        this.input.address.push({
          id: this.inputsAdess.id,
          name: this.inputsAdess.name,
          telephone: this.inputsAdess.phone,
          province: this.inputsAdess.provinces,
          city: this.inputsAdess.amphure,
          district: this.inputsAdess.tombons,
          address: this.inputsAdess.address,
          postal: this.inputsAdess.zipcode,
          status: this.inputsAdess.addressfirst
        })
        if (this.inputsAdess.addressfirst) this.onChangeDefaultAddress(this.input.address.length - 1)
      }

      this.IsopenmodalAddress = false
    },
    openModalAdd () {
      this.IsopenmodalAddress = true
      this.inputsAdess.provinces = ''
      this.inputsAdess.amphure = ''
      this.inputsAdess.tombons = ''
      this.inputsAdess.zipcode = ''
      this.inputsAdess.name = ''
      this.inputsAdess.address = ''
      this.inputsAdess.addressfirst = false
      this.inputsAdess.phone = ''
      this.inputsAdess.id = ''
      this.loaderAdress()
    },
    async loaderAdress () {
      await this.loadProvince()
      await this.loadAmphure()
      this.loadTombons()
    },
    loadProvince () {
      this.provinces = [{ value: '', text: 'เลือกจังหวัด' }]
      data_provinces.RECORDS.forEach(data => {
        this.provinces.push({ value: data.name_th, text: data.name_th })
      })
    },
    loadAmphure () {
      this.amphure = [{ value: '', text: 'เลือกอำเภอ' }]
      if (this.inputsAdess.provinces === '') return
      const idProvinces = data_provinces.RECORDS.filter(x => x.name_th === this.inputsAdess.provinces)[0].id
      data_amphure.RECORDS.forEach(data => {
        if (data.province_id.toString() === idProvinces) {
          this.amphure.push({ value: data.name_th, text: data.name_th })
        }
      })
    },
    loadTombons () {
      this.tombons = [{ value: '', text: 'เลือกตำบล' }]
      if (this.inputsAdess.amphure === '') return
      const idamphure = data_amphure.RECORDS.filter(x => x.name_th === this.inputsAdess.amphure)[0].DISTRICT_ID
      data_tombons.RECORDS.forEach(data => {
        if (data.amphure_id === idamphure.toString()) {
          this.tombons.push({ value: data.name_th, text: data.name_th })
        }
      })
    },
    async loader () {
      await this.loadCountry()
      this.employerid = JSON.parse(localStorage.userData).id
      if (this.codeforEdit !== '') {
        this.$store.dispatch('customer/getCustomerByCode', { code: this.code }).then(result => {
          this.codeId = result.code
          this.input.id = result._id
          this.input.firstName = result.firstName
          this.input.lastName = result.lastName
          this.input.type = result.type
          this.input.countryId = result.country
          this.pic = this.$baseURL + result.pic
          this.input.telephoneNumber = result.telephoneNumber
          this.input.email = result.email
          result.address.forEach(data => {
            this.input.address.push({
              id: data._id,
              name: data.name,
              telephone: data.telephone,
              province: data.province,
              city: data.city,
              district: data.district,
              address: data.address,
              postal: data.postal,
              status: data.status
            })
          })
        })
      }
      this.datascountry = data_country.RECORDS
    },
    loadCountry () {
      this.input.type = this.status
      this.DataCountry = [
        { value: null, text: 'โปรดเลือกประเทศ' }
      ]
      this.$store.dispatch('customer/getCountry').then(result => {
        result.data.items.forEach(element => {
          this.DataCountry.push({ value: element._id, text: element.thName })
        })
      })
    },
    onClickopenModalcountry () {
      this.openModalAddcountry = true
      this.inputscountry = ''
    },
    onClickAddcountry () {
      this.$refs.addCountryRules.validate().then(success => {
        const dtcountry = this.datascountry.filter(x => x.value === this.inputscountry)
        const Data = {
          thName: dtcountry[0].title,
          enName: dtcountry[0].enName,
          alpha2: dtcountry[0].alpha2
        }
        this.$store.dispatch('customer/addCountry', Data).then(result => {
          if (result.data.success) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'ประเทศ',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: result.data.message
              }
            })
            this.openModalAddcountry = false
            this.loadCountry()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'ประเทศ',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: result.data.message
              }
            })
          }
        })
      })
    },
    onChangeStatusCustomer (val) {
      this.input.type = val
      this.input.countryId = null
    },
    addCustomer () {
      this.$refs.customerRules.validate().then(success => {
        if (this.input.countryId === null && this.input.type === 'V') {
          this.checkIsSelect = false
          return
        }
        // if (this.input.firstName !== '' && this.input.lastName !== '' && this.input.type !== '' && this.input.telephoneNumber !== '') {
        if (success) {
          this.$store
            .dispatch('customer/addCustomer', this.input)
            .then(response => {
              if (response.data.success) {
                if (this.enter === 'page') {
                  this.$router.replace({ name: 'customer_list' })
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'ลูกค้า',
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: response.data.message
                        }
                      })
                    })
                } else {
                  this.$emit('setPageModal', 2)
                  this.$emit('loadCustomer')
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'ลูกค้า',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: response.data.message
                    }
                  })
                }
                this.input._id = response.data.id
                this.uploadPic()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'ลูกค้า',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: response.data.message
                  }
                })
              }
            }).catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ลูกค้า',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.data.message
                }
              })
            })
        }
      })
    },
    updateCustomer () {
      this.$refs.customerRules.validate().then(success => {
      // if (this.input.firstName !== '' && this.input.lastName !== '' && this.input.type !== '' && this.input.telephoneNumber !== '') {
        if (success) {
          console.log('WWWWW', this.input)
          this.uploadPic()
          this.$store
            .dispatch('customer/updateCustomer', this.input)
            .then(response => {
              if (response.data.success) {
                if (this.input.type === 'U') { this.$router.replace({ name: 'customer_list' }) } else { this.$router.push({ name: 'agent_list' }) }
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'ลูกค้า',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: response.data.message
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'ลูกค้า',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: response.data.message
                  }
                })
              }
            }).catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ลูกค้า',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.data.message
                }
              })
            })
        }
      })
    },
    onBackButton () {
      if (this.enter === 'page') {
        if (this.typeback === 'U') {
          this.$router.push({ name: 'customer_list' })
        } else if (this.typeback === 'V') {
          this.$router.push({ name: 'agent_list' })
        } else {
          this.$router.go(-1)
        }
      } else {
        this.$emit('setPageModal', 2)
      }
    },
    uploadPic () {
      if (this.file !== null) {
        const formData = new FormData()
        formData.append('customer', this.file)
        formData.append('id', this.input.id)
        this.$store.dispatch('upload/uploadCustomer', formData).then(success => {
          console.log(success)
        })
      }
    },
    setAltImg (e) {
      e.target.src = PlaceHolder
    }
  },
  watch: {
    file (e) {
      const newfile = e
      this.pic = URL.createObjectURL(newfile)
    },
    code () {
      if (this.codeforEdit === '') {
        this.input = {
          id: '',
          firstName: '',
          lastName: '',
          type: 'U',
          telephoneNumber: '',
          employerId: JSON.parse(localStorage.getItem('userData')).id,
          countryId: null,
          email: '',
          typeback: null,
          address: []

        }
      }
    },
    'input.countryId': {
      handler (val) {
        if (val !== null) {
          this.checkIsSelect = true
        }
      }
    },
    'inputsAdess.provinces': {
      handler (val) {
        this.loadAmphure()
      }
    },
    'inputsAdess.amphure': {
      handler () {
        this.loadTombons()
      }
    },
    'inputsAdess.tombons': {
      handler (val) {
        if (val !== '') {
          const dt = data_tombons.RECORDS.filter(x => x.name_th === val)
          this.inputsAdess.zipcode = dt[0].zip_code
        }
      }
    }
  } // END Watch
})
</script>
<style scoped>
  p {
    margin-top:11px
}
</style>
<style >
.modal-title{
    color: white;
}
.fromlabel label{
  margin-right: 15px;
}

</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
