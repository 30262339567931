<template>
  <div class="container-fuild">
    <div
      class="row"
      style="padding: 1.5rem;"
    >
      <div class="card col-12 col-sm-10 col-xl-9">
        <div class="card-body">
          <div class="mb-3">
            <h3>
              เพิ่ม/แก้ไข ลูกค้า
            </h3>
          </div>
          <addCustomer
            :code="code"
            :enter="enter"
            :status="status"
          />
        </div>

      </div>
    </div>

  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { BImg, BButton, BFormInput, BFormSpinbutton, BFormSelect, BFormFile, BFormTextarea, BFormDatepicker } from 'bootstrap-vue'
import addCustomer from '@/views/components/production/add_customer.vue'

export default defineComponent({
  name: 'customer_add',
  components: {
    BImg,
    BButton,
    BFormInput,
    BFormSpinbutton,
    BFormSelect,
    BFormFile,
    BFormTextarea,
    BFormDatepicker,
    addCustomer

  },
  data () {
    return {
      file: null,
      code: '',
      propDatas: {},
      enter: 'page',
      status: 'U'
    }
  },
  created () {
    if (this.$route.params.code !== '') {
      this.code = this.$route.params.code
    }
    if (this.$route.params.status !== undefined) {
      this.status = this.$route.params.status
    }
  },
  watch: {
    '$route.params.code': {
      handler (item) {
        this.code = ''
      }
    }
  }
})
</script>
<style scoped>
  p {
    margin-top:11px
}
</style>
